/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Culqui from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Unifef from "@components/sharedComponents/customersLogos/logos/Unifef.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import Astara from "@components/sharedComponents/customersLogos/logos/Astara.svg"
import Wally from "@components/sharedComponents/customersLogos/logos/Wally.svg"
import Proempresa from "@components/sharedComponents/customersLogos/logos/Proempresa.svg"
import Civa from "@components/sharedComponents/customersLogos/logos/Civa.svg"
import G4S from "@components/sharedComponents/customersLogos/logos/G4S.svg"
import Savar from "@components/sharedComponents/customersLogos/logos/Savar.svg"
import Escampa from "@components/sharedComponents/customersLogos/logos/Escampa.svg"
import Royal from "@components/sharedComponents/customersLogos/logos/Royal.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/chatbots-ia-casos-de-uso.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/chatbots-leads.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/script-chatbot.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/mejor-chatbot.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/chatbot-atencion-cliente.jpg"

// logos testimonios
import CasaRosselloTes from "@components/sharedComponents/testimonies/images/logos/casaRossello.svg"
import AstaraTes from "@components/sharedComponents/testimonies/images/logos/astara.svg"
import KontigoTes from "@components/sharedComponents/testimonies/images/logos/kontigo.svg"
import AvivaTes from "@components/sharedComponents/testimonies/images/logos/aviva.svg"
import CepebanTes from "@components/sharedComponents/testimonies/images/logos/cepeban.svg"

//foto testimonio desktop
import casaRosselloDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/casaRossello.png"
import astaraDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/astara.png"
import kontigoDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/kontigo.png"
import avivaDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/aviva.png"
import cepebanDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/cepeban.png"

//foto testimonio desktop
import casaRosselloMobile from "@components/sharedComponents/testimonies/images/fotosMobile/casaRossello.png"
import astaraMobile from "@components/sharedComponents/testimonies/images/fotosMobile/astara.png"
import kontigoMobile from "@components/sharedComponents/testimonies/images/fotosMobile/kontigo.png"
import avivaMobile from "@components/sharedComponents/testimonies/images/fotosMobile/aviva.png"
import cepebanMobile from "@components/sharedComponents/testimonies/images/fotosMobile/cepeban.png"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageColombia/pageChatbots/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageColombia/pageChatbots/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageColombia/pageChatbots/images/accordionLeft/imageThree.webp"
import ImageAccordionLeftFour from "@components/pageColombia/pageChatbots/images/accordionLeft/imageFour.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageChatbots.jpg"

import { TitleUnderlineIntro, TitleUnderlineIntroTwo  } from "@components/pageColombia/pageChatbots/titleUnderline/titleUnderlineAccordionRight"

import imageStartContactCenter from "@components/pageColombia/pageChatbots/images/imageIntro.webp"

import ImageVerticalOne from "@components/pageColombia/pageChatbots/images/imagesNavbarVertical/imageOne.webp"
import ImageVerticalTwo from "@components/pageColombia/pageChatbots/images/imagesNavbarVertical/imageTwo.webp"
import ImageVerticalThree from "@components/pageColombia/pageChatbots/images/imagesNavbarVertical/imageThree.webp"
import ImageVerticalFour from "@components/pageColombia/pageChatbots/images/imagesNavbarVertical/imageFour.webp"

const titleStart = (
  <p>
    Brindamos <TitleUnderlineIntro underline="chatbots" /> <TitleUnderlineIntro underline="con IA" />  a las empresas de Colombia que buscan automatizar su atención

  </p>
)
let data = {
  start: {
    title: titleStart,
    textbody: (
      <p>
        Automatiza la atención al cliente 24/7 con bots inteligentes que gestionan consultas, mejoran la experiencia del usuario y liberan a tu equipo para tareas estratégicas.
        <br /><br />
        Diseña chatbots con IA para atención, marketing y ventas, creando experiencias conversacionales que impulsan conversión y lealtad. Personaliza tus mensajes y utiliza enrutamiento inteligente para una comunicación más cercana y efectiva.
      </p>
    ),
    image: imageStartContactCenter,
    textbutton: "Solicitar demo",
    link: "/hablemos/",
  },
  customersDesktop: [
    Rimac,
    Culqui,
    Unifef,
    Auna,
    Astara,
    Wally,
    Proempresa,
    Civa,
    G4S,
    Savar,
    Escampa,
    Royal
  ],
  customersTablet: {
    arrayOne: [Rimac, Culqui, Unifef, Auna, Astara, Wally],
    arrayTwo: [Proempresa, Civa, G4S, Savar, Escampa, Royal],
  },
  articles: [
    {
      image: article1,
      title: "Chatbots IA: 6 casos de uso para atención al cliente",
      textbody:
        "Descubre cómo los chatbots IA pueden mejorar la atención del cliente con estos 6 casos de uso. ¡ENTRA AQUÍ y construye relaciones duraderas!",
      link: "https://beexcc.com/blog/chatbots-ia-casos-de-uso/",
      card: true,
    },
    {
      image: article2,
      title: "¿Cómo califican los chatbots a los leads para ganar clientes?",
      textbody:
        "Descubre cómo califican los chatbots a los leads de tu negocio y porque es importante si quieres saber cómo ganar clientes por internet.",
      link: "https://beexcc.com/blog/chatbots-leads/",
      card: true,
    },
    {
      image: article3,
      title: "6 pasos para crear un script para tu chatbot",
      textbody:
        "¿Tienes un chatbot o piensas implementar uno? Aquí te contamos cuál es la forma correcta que deben comunicarse los bots con tus clientes.",
      link: "https://beexcc.com/blog/script-chatbot/",
    },
    {
      image: article4,
      title: "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
      textbody:
        "Hoy vas a saber cómo elegir el mejor chatbot para tu negocio. De esta forma, la inversión que llegues a hacer no será en vano.",
      link: "https://beexcc.com/blog/mejor-chatbot/",
    },
    {
      image: article5,
      title: "Chatbot para atención al cliente: ¿Qué usos debes darle?",
      textbody:
        "¿Quieres usar chatbots para tu atención al cliente? En este artículo te explicamos qué usos le puedes dar en beneficio de tus consumidores.",
      link: "https://beexcc.com/blog/chatbot-atencion-cliente/",
    },
  ],
  contacts: {
    title: "Potencia tus operaciones de Contact Center con tecnología cloud",
    textbody: "Cuéntanos sobre tus operaciones outbound o inbound para que nuestros asesores te brinden un feedback que te permita mejorar tus servicios.",
    href: "/hablemos/",
    btn: "Quiero una asesoría gratuita",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  testimonies: [
    {
      image: CasaRosselloTes,
      title:
        "Con los chatbots de  Beex, hemos mejorado la prospección de nuestros leads",
        link: "/casos-de-exito/casa-rossello/",
        industrie: "Ecommerce",
        backgroundIndustrie: "#D60000",
        backgroundFondo: "#D6000033",
      metrics: [
        {
          metric: "+75%",
          descripcion: "aumento de leads digitales",
          color: "#FFB800",
        },
        {
          metric: "+65%",
          descripcion: "aumento de recompra",
          color: "#5831D8",
        },
      ],
    },
    {
      image: AstaraTes,
      title:
        "Vendemos 3x más al mes automatizando nuestro embudo de ventas con chatbots en WhatsApp",
        link: "/casos-de-exito/astara/",
      industrie: "Concesionarias",
      backgroundIndustrie: "#231E36",
      backgroundFondo: "#231E3633",
      metrics: [
        {
          metric: "x3",
          descripcion: "aumentó sus ventas digitales",
          color: "#FFB800",
        },
        {
          metric: "93%",
          descripcion: "redujo su esfuerzo para remarketing",
          color: "#5831D8",
        },
        {
          metric: "80%",
          descripcion: "mejoró contactabilidad con leads",
          color: "#00CA56",
        },
      ],
    },
    {
      image: KontigoTes,
      title:
        "Con  Beex, somos capaces de cerrar el 98% de las consultas en primer contacto",
      link: "/casos-de-exito/kontigo/",
       industrie: "Banca y finanzas",
      backgroundIndustrie: "#197DF2",
      backgroundFondo: "#197DF233",
      metrics: [
        {
          metric: "98%",
          descripcion: "casos cerrados en primer contacto",
          color: "#FFB800",
        },
        {
          metric: "<1 minuto",
          descripcion: "tiempo de primera respuesta",
          color: "#5831D8",
        },
        {
          metric: "x3",
          descripcion: "mejoró el tiempo de resolución de casos",
          color: "#00CA56",
        },
      ],
    },
    {
      image: AvivaTes,
      title:
        "Mejoramos el tiempo para agendar citas en +30%, automatizando procesos con chatbots por WhatsApp",
        link: "/casos-de-exito/clinica-aviva/",
        industrie: "Salud",
         backgroundIndustrie: "#007791",
         backgroundFondo: "#00779133",
      metrics: [
        {
          metric: "+30%",
          descripcion: "mejoró el tiempo de agenda de citas",
          color: "#FFB800",
        },
        {
          metric: "+20%",
          descripcion: "ahora representa WhatsApp como canal para agendar citas",
          color: "#5831D8",
        },
      ],
    },
    {
      image: CepebanTes,
      title: "Con los chatbots de prospección, hemos mejorado la conversión de leads en +27%",
          link: "/casos-de-exito/cepeban/",
      industrie: "Educación",
      backgroundIndustrie: "#FF1A2B",
      backgroundFondo: "#FF1A2B33",
      metrics: [
        {
          metric: "+27%",
          descripcion: "mejoró su tasa de conversión con sus leads",
          color: "#FFB800",
        },
        {
          metric: "+1.5k",
          descripcion: "leads prospectados al mes por chatbots",
          color: "#5831D8",
        },
        {
          metric: "25%",
          descripcion: "mejoró el tiempo de resolución de casos",
          color: "#00CA56",
        },
      ],
    },
    
  ],
  accordionRight: [
    {
      title: "Potencia tu servicio con la IA de Beex",
      description: "<p>Empodera a tus clientes para resolver consultas y realizar transacciones de forma autónoma con chatbots de IA e IVR. Desde seguimiento de pedidos hasta pagos, integra estas herramientas con tu CRM o ERP para ofrecer una experiencia rápida y personalizada.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "Workflows y automatizaciones inteligentes",
      description: "<p>Automatiza tareas repetitivas con workflows integrados en chatbots, como seguimiento de pedidos o programación de citas. Mejora la eficiencia operativa de tu empresa al reducir la necesidad de intervención humana.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "Integraciones vía APIs escalables",
      description: "<p>Conecta tu bot con herramientas como HubSpot, Salesforce o Zoho mediante nuestras APIs. Gestiona carritos abandonados, realiza actualizaciones en tiempo real y haz seguimiento de pedidos para brindar una atención integral y fluida.</p>",
      image: ImageAccordionLeftThree,
    },
    {
      title: "Supervisión en tiempo real",
      description: "<p>Monitorea y audita las actividades de tus agentes mientras trabajan. Identifica áreas de mejora y refuerza su desempeño con feedback oportuno, asegurando un servicio de alta calidad.</p>",
      image: ImageAccordionLeftFour
    },
  ],
  bannerVideo: {
    title: "Chatbot: Qué es, cómo funciona y cuáles son sus beneficios",
    description: "<p>En este video te contamos todo sobre chatbots. ¿Qué son? ¿Cómo funcionan? ¿Cuáles son sus beneficios según tu negocio? Todo con ejemplos de uso.  <br /> <br />Nuestros líderes de Beex, nuestra plataforma omnicanal con chatbots, Luismi y Gaby, nos cuentan claramente los 5 beneficios principales que tendrás al implementar chatbots en tu operación.</p>",
    image: imageVideo
  },
  navbarVerticalRight: [
    {
      tab: "1",
      title: "Proceso de Citas y Reservas",
      text: (
        <p>
          Automatiza la programación de citas y reservas, permitiendo a los clientes elegir fechas y horarios a través de una interfaz conversacional fácil de usar, simplificando el proceso y mejorando la satisfacción.
        </p>
      ),
      image: ImageVerticalOne,
      active: "container-navbar-vertical-right-navbar-menu-button-active",
    },
    {
      tab: "2",
      title: "Gestión de Pedidos",
      text: (
        <p>
         Permite a tus usuarios tomar y procesar pedidos directamente a través de WhatsApp, ofreciendo una experiencia única de autoservicio luego de una compra, reduciendo la carga operativa del equipo de ventas.
        </p>
      ),
      image: ImageVerticalTwo,
    },
  ],
  navbarVerticalLeft: [
    {
      tab: "3",
      title: "Mensajes Interactivos",
      text: (
        <p>
Implementa mensajes interactivos para enriquecer la experiencia del usuario, permitiendo a los clientes tomar acciones directas dentro de la conversación, como seleccionar opciones en botones o listas de mensajes.
        </p>
      ),
      image: ImageVerticalThree,
      active: "container-navbar-vertical-left-navbar-menu-button-active",
    },
    {
      tab: "4",
      title: "Mensajes Multimedia",
      text: (
        <p>
          Envía mensajes que incluyen imágenes, videos, documentos y audios para proporcionar una experiencia más única y atractiva, ideal para mostrar productos, instrucciones o información relevante.
        </p>
      ),
      image: ImageVerticalFour,
    },
  ],
  navbarVerticalSilder: [
    {
      tab: "1",
      title: "Proceso de Citas y Reservas",
      text: (
        <p>
          Automatiza la programación de citas y reservas, permitiendo a los clientes elegir fechas y horarios a través de una interfaz conversacional fácil de usar, simplificando el proceso y mejorando la satisfacción.
        </p>
      ),
      image: ImageVerticalOne,
      active: "container-navbar-vertical-right-navbar-menu-button-active",
    },
    {
      tab: "2",
      title: "Gestión de Pedidos",
      text: (
        <p>
          Permite a tus usuarios tomar y procesar pedidos directamente a través de WhatsApp, ofreciendo una experiencia única de autoservicio luego de una compra, reduciendo la carga operativa del equipo de ventas.
        </p>
      ),
      image: ImageVerticalTwo,
    },
    {
      tab: "3",
      title: "Mensajes Interactivos",
      text: (
        <p>
          Implementa mensajes interactivos para enriquecer la experiencia del usuario, permitiendo a los clientes tomar acciones directas dentro de la conversación, como seleccionar opciones en botones o listas de mensajes.
        </p>
      ),
      image: ImageVerticalThree,
    },
    {
      tab: "4",
      title: "Mensajes Multimedia",
      text: (
        <p>
          Envía mensajes que incluyen imágenes, videos, documentos y audios para proporcionar una experiencia más única y atractiva, ideal para mostrar productos, instrucciones o información relevante.
        </p>
      ),
      image: ImageVerticalFour,
      active: "container-navbar-vertical-left-navbar-menu-button-active",
    },
  ],
}

export default data
